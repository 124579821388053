import React from 'react';

import { Box, Breadcrumbs, Divider, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Slider from 'react-slick';

import placeHolderImage from 'assets/img/placeholder.jpg';
import ButtonSx from 'components/button/ButtonSx';

const Material = () => {
  const navigate = useNavigate();
  const params = useParams();
  const breadcrumbPaths = Object.values(params);
  const { materialType, materialId } = useParams();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    cssEase: 'linear',
  };

  const handleBreadcrumbClick = () => {
    navigate(`/select-material/${materialType}`);
  };
  return (
    <>
      <Stack alignItems={'center'} sx={{ p: 3 }}>
        <Stack
          gap={3}
          width={'100%'}
          sx={{
            flexDirection: { xs: 'column', lg: 'row' },
            justifyContent: { xs: 'normal', lg: 'center' },
            alignItems: { xs: 'center', lg: 'normal' },
          }}
        >
          <Stack gap={2}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbPaths?.splice(1, 3)?.map((crumb, index) => {
                const isLast = crumb === Object.values(params)?.at(-1);

                return isLast ? (
                  <Typography key={index} color="text.primary">
                    {crumb}
                  </Typography>
                ) : (
                  <Typography
                    key={index}
                    color="inherit"
                    onClick={() => handleBreadcrumbClick()}
                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    {crumb}
                  </Typography>
                );
              })}
            </Breadcrumbs>
            <img
              src={placeHolderImage}
              alt="logo"
              width={'100%'}
              style={{
                height: 'auto',
                objectFit: 'contain',
                border: '1px solid #EEEEEE',
                cursor: 'pointer',
              }}
            />
            <Box width={'500px'} sx={{ ml: 2 }}>
              <Slider {...settings}>
                {[1, 2, 3, 4, 5, 6, 7]?.map((item) => (
                  <div key={item}>
                    <img
                      src={placeHolderImage}
                      alt="logo"
                      width={'100px'}
                      style={{
                        height: 'auto',
                        objectFit: 'contain',
                        border: '1px solid #EEEEEE',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                ))}
              </Slider>
            </Box>
          </Stack>
          <Stack maxWidth={'700px'} width={'100%'} gap={2}>
            <Typography
              sx={{ textAlign: 'start', fontWeight: 600, fontSize: 36 }}
            >
              {materialId}
            </Typography>
            <Divider />

            <Stack
              alignItems={'center'}
              justifyContent={'space-between'}
              height={'100%'}
              gap={3}
            >
              <Stack
                direction={'row'}
                gap={3}
                justifyContent={'space-around'}
                width={'100%'}
              >
                <Typography>TFL: L586 (K)</Typography>
                <Typography>TFL: L586 (K)</Typography>
              </Stack>
              <ButtonSx
                buttonText="Select this material"
                onButtonPress={() => {}}
                isLoading={false}
                sx={{
                  borderRadius: '5px',
                  height: 50,
                  width: '70%',
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Material;
