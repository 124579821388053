import React, { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Checkbox, Divider, IconButton, Tooltip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import FileSaver from 'file-saver';
import debounce from 'lodash.debounce';
import moment from 'moment';
import './Orders.css';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import AlertSx from 'components/alert/AlertSx';
import ProjectPopup from 'components/project-popup/ProjectPopup';
import useAuth from 'context/AuthContext';
import {
  useGetCustomerQuery,
  useGetSearchedUsersQuery,
} from 'store/apis/customer';
import { pxToRem } from 'theme-loader/theme/typography';
import { b64toBlob, downloadPDF } from 'utils/commonFunctions';
import { useNotifyToast } from 'utils/useNotifyToast';

import OrderItemDialog from './components/OrderItemDialog';
import {
  useCreateDuplicateProjectsMutation,
  useGetAllProjectsOfUserQuery,
  useGetOrdersQuery,
  useLazyDownloadReviewOrderQuery,
  useLazyGetUploadedFileQuery,
} from '../../store/apis/order';

const validationSchema = yup.object({
  projects: yup.array().of(
    yup.object({
      name: yup.string().required('Project name cannot be empty'),
    })
  ),
});

export const datagridSx = {
  '&.MuiDataGrid-root': {
    border: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: 14,
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-columnHeader': {
    borderRadius: 0,
  },
  '.MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '.MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
    outline: 'none !important',
  },
  '.MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
  '&>.MuiDataGrid-main': {
    '& .MuiDataGrid-row--borderBottom': {
      borderBottom: 'none',
      backgroundColor: '#E5E5E5',
    },

    '& .MuiDataGrid-cell': {
      borderTop: 'none',
      boxSizing: 'none',
      borderLeft: 'none',
    },
  },
};

export default function Orders() {
  const notifyToast = useNotifyToast();
  const [filter, setFilter] = React.useState({
    sortBy: 'createdAt',
    orderBy: 'desc',
    page: 1,
    limit: 10,
  });
  const [sortModel, setSortModel] = useState({});
  const [customerFilter, setCustomerFilter] = React.useState({
    page: 1,
    limit: 10,
  });
  const auth = useAuth();
  const { data: user } = useGetCustomerQuery(
    {},
    { skip: !auth?.authenticated }
  );

  const [
    getUploadedFile,
    { isFetching: isDesignDownloading, originalArgs: designOgArgs },
  ] = useLazyGetUploadedFileQuery();
  const [handleDownload, { isFetching: isFetchingPdf, originalArgs }] =
    useLazyDownloadReviewOrderQuery();
  const [value, setValue] = React.useState([]);
  const [error, setError] = useState({
    isError: false,
    errorMessage: '',
  });

  const [
    createDuplicateProjects,
    {
      data: duplicateProjectsData,
      isLoading: isDuplicatingProjectsLoading,
      isSuccess: isDuplicatingProjectsSuccess,
      isError: isDuplicatingProjectsError,
      error: addDuplicateProjectsError,
    },
  ] = useCreateDuplicateProjectsMutation();
  const {
    control,
    reset,
    setValue: setDefaultValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      projectsFields: [],
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { fields, append } = useFieldArray({
    control,
    name: 'projectsFields',
  });

  useEffect(() => {
    if (sortModel && sortModel?.length) {
      const { field, sort } = sortModel[0];
      setFilter((prevState) => ({
        ...prevState,
        sortBy: field,
        orderBy: sort,
      }));
    }
  }, [sortModel]);

  useEffect(() => {
    if (!isDuplicatingProjectsLoading) {
      if (isDuplicatingProjectsSuccess) {
        notifyToast(
          duplicateProjectsData?.message,
          'projectsDuplicated',
          'success'
        );
      } else if (isDuplicatingProjectsError) {
        notifyToast(
          addDuplicateProjectsError?.data?.errorMessage,
          'projectsDuplicationFailed',
          'error'
        );
      }
    }
  }, [
    addDuplicateProjectsError?.data?.errorMessage,
    duplicateProjectsData?.message,
    isDuplicatingProjectsError,
    isDuplicatingProjectsLoading,
    isDuplicatingProjectsSuccess,
    notifyToast,
  ]);

  const {
    data: allCustomerData,

    isFetching: isCustomersFetching,
  } = useGetSearchedUsersQuery(customerFilter, {
    skip: user?.role !== 'ADMIN',
  });

  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const [isProjectPopupOpen, setProjectPopupOpen] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [currentOrderId, setCurrentOrderId] = useState(null);

  const handlePdf = useCallback(
    async (order) => {
      const response = await handleDownload({
        id: order?.id,
        orderPlaced: true,
      });
      if (response.data) {
        const parsedData = JSON.parse(response?.data);
        const blob = b64toBlob(parsedData?.stream);
        const blobUrl = URL.createObjectURL(blob);
        downloadPDF({ blobUrl, fileName: order?.purchaseOrderNo });
      } else {
        setError({
          isError: true,
          errorMessage: 'Something went wrong!',
        });
      }
    },
    [handleDownload]
  );

  const downloadDesignFile = async (name) => {
    const response = await getUploadedFile(name);
    if (response?.error?.data?.errorMessage) {
      setError({
        isError: true,
        errorMessage: response?.error?.data?.errorMessage,
      });
    } else {
      downloadPDF({ blobUrl: response?.data?.url, name });
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', sortable: false },
    {
      field: 'createdAt',
      headerName: 'Date',
      sortable: true,
      minWidth: 165,
      flex: 1,
      renderCell: (params) => {
        return moment(params.value).format('MMMM DD, YYYY');
      },
    },
    ...(user?.role === 'ADMIN'
      ? [
          {
            field: 'firstName',
            headerName: 'Name',
            minWidth: 150,
            flex: 1,
            renderCell: (params) => {
              return params.row.firstName + ' ' + params.row.lastName;
            },
          },
          { field: 'email', headerName: 'Email', minWidth: 280, flex: 1 },
          {
            field: 'phone',
            headerName: 'Phone',
            minWidth: 150,
            flex: 1,
            sortable: false,
          },
        ]
      : []),
    {
      field: 'orderStatus',
      headerName: 'Status',
      sortable: true,
      minWidth: 125,
      flex: 1,
    },
    {
      field: 'purchaseOrderNo',
      headerName: 'Order #',
      sortable: true,
      minWidth: 145,
      flex: 1,
    },
    {
      field: 'projectName',
      headerName: 'Projects',
      sortable: true,
      minWidth: 155,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack height={'100%'} justifyContent={'center'}>
            <Tooltip
              title={params?.row?.projects
                .map((item) => item.projectName)
                .join(', ')}
              placement="top"
              arrow
            >
              <Typography
                noWrap
                sx={{
                  maxWidth: '100px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {params?.row?.projects
                  .map((item) => item.projectName)
                  .join(', ')}
              </Typography>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: 'jobName',
      headerName: 'Job Name',
      sortable: true,
      minWidth: 155,
      flex: 1,
    },
    {
      field: 'customerPO',
      headerName: 'Purchase Order #',
      sortable: true,
      minWidth: 155,
      flex: 1,
    },
    {
      field: 'address',
      headerName: 'Delivery Address',
      sortable: false,
      minWidth: 245,
      flex: 1,
    },
    {
      field: 'note',
      headerName: 'Notes / Special requests',
      sortable: false,
      minWidth: 245,
      flex: 1,
    },
    {
      field: 'totalCost',
      headerName: 'Total Cost',
      sortable: true,
      minWidth: 140,
      flex: 1,
    },

    {
      field: 'Action',
      headerName: 'Action',
      sortable: false,
      minWidth: 140,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            container
            direction="row"
            justifyContent="space-around"
            spacing={2}
            alignItems="center"
            height={'100%'}
          >
            <Button
              sx={{
                fontSize: '14px',
              }}
              onClick={() => {
                setOpenDialog(true);
                setSelectedItem(params);
              }}
            >
              View Details
            </Button>
          </Stack>
        );
      },
    },
    {
      field: 'Download',
      headerName: 'Download',
      sortable: false,
      minWidth: user?.role === 'ADMIN' ? 260 : 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            container
            direction="row"
            justifyContent="space-around"
            spacing={2}
            alignItems="center"
            height={'100%'}
          >
            {user?.role === 'ADMIN' ? (
              <Button
                sx={{
                  fontSize: '14px',
                }}
                onClick={async () => {
                  fetch(
                    `${process.env.REACT_APP_BASE_URL}/download-order/${params?.row?.id}`,
                    {
                      method: 'GET',
                    }
                  )
                    .then((response) => {
                      return response.text();
                    })
                    .then((zipAsBase64) => {
                      const blob = b64toBlob(
                        window.btoa(zipAsBase64),
                        'application/octet-stream'
                      );
                      FileSaver.saveAs(
                        blob,
                        `${params?.row?.purchaseOrderNo}.ord`
                      );
                    })
                    .catch((error) => {
                      console.error(error);
                      alert('Error downloading orders');
                    });
                }}
              >
                ORD
              </Button>
            ) : null}
            <Button
              sx={{
                fontSize: '14px',
                minWidth: '88px',
              }}
              onClick={() => handlePdf(params?.row)}
            >
              {isFetchingPdf && originalArgs?.id === params?.row?.id ? (
                <CircularProgress thickness={2} size={20} />
              ) : (
                'Order Detail'
              )}
            </Button>
            {params?.row?.designFile ? (
              <Button
                sx={{
                  fontSize: '14px',
                }}
                onClick={() => downloadDesignFile(params?.row?.designFile)}
              >
                {isDesignDownloading &&
                designOgArgs === params?.row?.designFile ? (
                  <CircularProgress thickness={2} size={20} />
                ) : (
                  'Design'
                )}
              </Button>
            ) : null}
          </Stack>
        );
      },
    },
    {
      field: 'viewInClickup',
      headerName: 'View in clickup',
      sortable: false,
      minWidth: 140,
      flex: 1,
      renderCell: (params) => {
        return params?.row?.taskId ? (
          <Button
            sx={{
              fontSize: '14px',
            }}
            onClick={() => {
              if (params?.row?.taskId) {
                window.open(
                  `https://app.clickup.com/t/${params?.row?.taskId}`,
                  '_blank'
                );
              }
            }}
          >
            View
          </Button>
        ) : null;
      },
    },
    {
      field: 'duplicateProject',
      headerName: 'Duplicate Project',
      sortable: false,
      minWidth: 140,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            alignItems="center"
            justifyContent={'center'}
            width={'100%'}
            height={'100%'}
          >
            <IconButton
              onClick={() => {
                setCurrentOrderId(params?.row?.id);
                append(
                  params?.row?.projects?.map((item) => ({
                    name: item?.projectName,
                    duplicatedFrom: item?.projectId,
                    orderProjectsId: item?.id,
                    selfDuplicationId: item?.duplicatedFrom,
                  }))
                );
                setProjectPopupOpen(true);
              }}
              color="inherit"
            >
              <ContentCopyIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const { data, isLoading, isFetching } = useGetOrdersQuery(filter);
  const { data: allProjects } = useGetAllProjectsOfUserQuery(
    {},
    { skip: !user?.id || !auth?.authenticated }
  );

  const handleClose = () => {
    setOpenDialog(false);
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const debouncedChangeHandler = debounce(
    (e) =>
      setCustomerFilter((state) => ({
        ...state,
        search: e.target.value,
      })),
    300
  );

  const handleCloseAlert = () => {
    setSelectedProjects([]);
    reset();
    setProjectPopupOpen(false);
  };

  // code to handle the increment of duplicated projects

  const mergedObject = {};
  fields?.forEach((project) => {
    if (!mergedObject[project.name]) {
      mergedObject[project.name] = 1;
    }
    // note: here the "duplicatedFrom" id in fields is the id of the project only
    allProjects?.forEach((item) => {
      if (item?.duplicatedFrom === project?.duplicatedFrom) {
        mergedObject[project.name] = mergedObject[project.name] + 1;
      }
    });
  });

  return (
    <Box sx={{ height: '100%', mx: 2 }}>
      <Grid container pt={4} mb={3.5} width="100%">
        <Grid item xs={12} sm={6} lg={8}>
          <Typography
            sx={{
              fontSize: pxToRem(30),
              fontWeight: 700,
              ml: { xs: 1, md: 0 },
            }}
          >
            My orders
          </Typography>
        </Grid>
        <Grid item sm={6} xs={12} lg={4} sx={{ mt: { xs: 2, sm: 0 } }}>
          {user?.role === 'ADMIN' ? (
            <Autocomplete
              multiple
              limitTags={2}
              id="multiple-limit-tags"
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {`${option.firstName} ${option.lastName} (${option.email})`}
                  </li>
                );
              }}
              onChange={(event, newValue) => {
                if (value.length > 4 && newValue.length >= 5) {
                  notifyToast(
                    'Maximum 5 customers can be selected',
                    'maxCustomer',
                    'info'
                  );
                } else {
                  setValue(newValue?.map((option) => option.value || option));
                  const customers = newValue?.map((item) => item.id);
                  setFilter((prevState) => ({ ...prevState, customers }));
                }
              }}
              value={value}
              isOptionEqualToValue={(option, value) => {
                return option?.id === value?.id;
              }}
              options={allCustomerData?.data || []}
              loading={isCustomersFetching}
              loadingText={'Searching...'}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName} (${option.email})`
              }
              onSelect={debouncedChangeHandler}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by customer"
                  placeholder="Search or select customer"
                />
              )}
            />
          ) : null}
        </Grid>
      </Grid>
      <Paper elevation={0} sx={{ mb: 8 }}>
        <DataGrid
          onSortModelChange={(model) => {
            setSortModel(model);
          }}
          sortingMode="server"
          sortingOrder={['desc', 'asc']}
          autoHeight
          disableColumnMenu
          sx={datagridSx}
          initialState={{
            sorting: {
              sortModel: [{ field: 'createdAt', sort: 'desc' }],
            },
            pagination: {
              page: 1,
              pageSize: 10,
            },
            columns: {
              columnVisibilityModel: {
                id: false,
                viewInClickup: user?.role === 'ADMIN',
              },
            },
          }}
          rowCount={data?.counts || 0}
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          pagination
          paginationMode="server"
          paginationModel={{
            page: filter.page - 1,
            pageSize: filter.limit,
          }}
          onPaginationModelChange={(e) => {
            const { page, pageSize } = e;
            setFilter({ ...filter, page: page + 1, limit: pageSize });
          }}
          rows={
            data?.data?.map((item) => ({
              ...item,
              purchaseOrderNo: item.purchaseOrderNo,
              customerPO: item.customerPO,
              totalCost: Number(item.totalCost).toFixed(2),
              totalItem: Number(item.totalItem),
              discountAmount: Number(item.discountAmount),
            })) || []
          }
          columns={columns}
          loading={isLoading || isFetching}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Orders
              </Stack>
            ),
          }}
        />
      </Paper>
      {openDialog && (
        <OrderItemDialog
          open={openDialog}
          handleClose={handleClose}
          selectedItem={selectedItem}
        />
      )}
      <AlertSx
        message={error.errorMessage}
        severity="error"
        showAlert={!!error.isError}
        handleClose={() =>
          setError({
            isError: false,
            errorMessage: '',
          })
        }
        sx={{ top: { xs: 100 } }}
        autoHideDuration={2000}
      />
      {isProjectPopupOpen && (
        <ProjectPopup
          desc={'Please choose projects to duplicate from'}
          isOpen={isProjectPopupOpen}
          onClose={handleCloseAlert}
          title={'Duplicate projects'}
          submitButtonText={'Duplicate'}
          paperStyles={{ minWidth: '600px' }}
          isConfirmDisabled={
            !selectedProjects?.length || !!Object.keys(errors)?.length
          }
          isConfirmLoading={isDuplicatingProjectsLoading}
          childComponent={
            <>
              <Divider sx={{ mb: 1 }} />
              <form>
                <Stack
                  gap={2}
                  sx={{
                    p: 1,
                    overflowY: 'auto',
                    height: 'auto',
                    maxHeight: '300px',
                  }}
                >
                  {fields?.map((project, index) => {
                    return (
                      <Stack
                        key={project?.duplicatedFrom}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        gap={2}
                      >
                        {selectedProjects?.some(
                          (item) =>
                            item?.duplicatedFrom === project?.duplicatedFrom
                        ) ? (
                          <Controller
                            key={project?.duplicatedFrom}
                            control={control}
                            name={`projects.${index}.name`}
                            defaultValue={`${project?.name}-copy-${
                              mergedObject[project?.name]
                            }`}
                            render={({ field }) => {
                              const { onChange } = field;
                              return (
                                <TextField
                                  {...field}
                                  onChange={(e) => {
                                    const newValue = e.target.value;

                                    setSelectedProjects((prev) =>
                                      prev.map((item) =>
                                        item?.duplicatedFrom ===
                                        project?.duplicatedFrom
                                          ? { ...item, name: newValue }
                                          : item
                                      )
                                    );
                                    onChange(e);
                                  }}
                                  key={project?.duplicatedFrom}
                                  sx={{ width: '100%' }}
                                  error={!!errors.projects?.[index]?.name}
                                  helperText={
                                    errors.projects?.[index]?.name?.message
                                  }
                                  inputProps={{ maxLength: 70 }}
                                />
                              );
                            }}
                          />
                        ) : (
                          <Typography sx={{ p: 1.8 }}>
                            {project?.name}
                          </Typography>
                        )}
                        <Checkbox
                          id={project?.duplicatedFrom}
                          checked={selectedProjects?.some(
                            (item) =>
                              item?.duplicatedFrom === project?.duplicatedFrom
                          )}
                          onChange={(e) => {
                            const { checked } = e.target;
                            if (!checked) {
                              clearErrors(`projects.${index}.name`);
                              setDefaultValue(
                                `projects.${index}.name`,
                                `${project?.name}-copy-${
                                  mergedObject[project?.name]
                                }`
                              );
                            }
                            setSelectedProjects((prev) =>
                              checked
                                ? [
                                    ...prev,
                                    {
                                      duplicatedFrom: project?.duplicatedFrom,
                                      name: `${project?.name}-copy-${
                                        mergedObject[project?.name]
                                      }`,
                                      orderProjectsId: project?.orderProjectsId,
                                    },
                                  ]
                                : prev.filter(
                                    (item) =>
                                      item?.duplicatedFrom !==
                                      project?.duplicatedFrom
                                  )
                            );
                          }}
                        />
                      </Stack>
                    );
                  })}
                </Stack>
              </form>
              {!!selectedProjects?.length && (
                <Typography sx={{ p: 1 }}>
                  Note: Make sure to verify the name of the duplicated project
                </Typography>
              )}
            </>
          }
          onConfirm={() => {
            createDuplicateProjects({
              data: selectedProjects?.map((item) => ({
                ...item,
                orderInfoId: currentOrderId,
              })),
            });
            handleCloseAlert();
          }}
        />
      )}
    </Box>
  );
}
