import React from 'react';

import { TextField, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
const DynamicForm = ({ register, errors, fields }) => {
  const renderField = (field) => {
    switch (field.type) {
      case 'dropdown':
        return (
          <TextField
            select
            label={field.name}
            fullWidth
            defaultValue={field.value || ''}
            {...register(field.id, { required: `${field.name} is required` })}
            error={!!errors[field.id]}
            helperText={errors[field.id]?.message}
          >
            {field.options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        );
      case 'text':
        return (
          <TextField
            label={field.name}
            fullWidth
            defaultValue={field.value || ''}
            {...register(field.id, {
              required: `${field.name} is required`,
              maxLength: { value: 50, message: 'Max 50 characters allowed' },
            })}
            error={!!errors[field.id]}
            helperText={errors[field.id]?.message}
          />
        );
      case 'number':
        return (
          <TextField
            label={field.name}
            fullWidth
            type="number"
            defaultValue={field.value || ''}
            {...register(field.id, {
              required: `${field.name} is required`,
              min: { value: 0, message: 'Minimum value is 0' },
            })}
            error={!!errors[field.id]}
            helperText={errors[field.id]?.message}
          />
        );
      default:
        return null;
    }
  };

  return (
    <form>
      {fields?.map((field) => (
        <div key={field.id} style={{ marginBottom: '1rem' }}>
          {renderField(field)}
        </div>
      ))}
    </form>
  );
};

export default DynamicForm;

DynamicForm.propTypes = {
  fields: PropTypes.array,
  register: PropTypes.any,
  errors: PropTypes.object,
};
