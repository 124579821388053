import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddToPhotosSharpIcon from '@mui/icons-material/AddToPhotosSharp';
import ArrowCircleDownSharpIcon from '@mui/icons-material/ArrowCircleDownSharp';
import CallSplitSharpIcon from '@mui/icons-material/CallSplitSharp';
import CategoryIcon from '@mui/icons-material/Category';
import ContactsIcon from '@mui/icons-material/Contacts';
// import FeaturedPlayListSharpIcon from '@mui/icons-material/FeaturedPlayListSharp';
import CreateIcon from '@mui/icons-material/Create';
import GradeSharpIcon from '@mui/icons-material/GradeSharp';
import HelpIcon from '@mui/icons-material/Help';
import HelpCenterSharpIcon from '@mui/icons-material/HelpCenterSharp';
import HistorySharpIcon from '@mui/icons-material/HistorySharp';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import InfoIcon from '@mui/icons-material/Info';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LoginSharpIcon from '@mui/icons-material/LoginSharp';
import ArrowCircleUpSharpIcon from '@mui/icons-material/OpenInBrowserSharp';
// import PaymentIcon from '@mui/icons-material/Payment';
// import PaidIcon from '@mui/icons-material/Paid';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PortraitSharpIcon from '@mui/icons-material/PortraitSharp';
export const investUrl =
  'https://www.figure.com/invest/listings/6fa98316-2b66-4af8-8b8e-fb9484c84e8b/';

export const aboutLinks = [
  {
    text: 'Our company',
    link: 'https://www.hellobaru.com/about',
  },
  // {
  //   text: 'Team',
  //   link: 'https://www.barucabinets.com/about',
  // },
  // {
  //   text: 'Jobs',
  //   link: 'https://www.barucabinets.com/about',
  // },
  // {
  //   text: 'Investors',
  //   link: 'https://www.barucabinets.com/about',
  // },
  // {
  //   text: 'Invest',
  //   link: 'https://www.figure.com/invest/listings/6fa98316-2b66-4af8-8b8e-fb9484c84e8b/',
  // },
  // {
  //   text: 'Terms of service',
  //   link: 'https://www.barucabinets.com/about',
  // },
  // {
  //   text: 'Privacy policy',
  //   link: 'https://www.barucabinets.com/about',
  // },
];

export const storeLinks = [
  {
    text: 'Base cabinets',
    path: '/cabinet/base-cabinets',
  },
  {
    text: 'Wall cabinets',
    path: '/cabinet/wall-cabinets',
  },
  {
    text: 'Tall cabinets',
    path: '/cabinet/tall-cabinets',
  },
  {
    text: 'Corner cabinets',
    path: '/cabinet/corner-cabinets',
  },
  {
    text: 'Oven cabinets',
    path: '/cabinet/oven-cabinets',
  },
  {
    text: 'Additional Items',
    path: '/cabinet/additional-items',
  },
];

export const supportLinks = [
  {
    text: 'FAQ',
    // path: '/faq',
    link: 'http://hellobaru.com/faq',
  },
  // {
  //   text: 'Shipping',
  // },
  {
    text: 'Request support',
    path: '/request-support',
  },
];

export const authPages = [
  // {
  //   title: 'Invest',
  //   path: investUrl,
  //   icon: PaidIcon,
  // },

  {
    title: 'My Projects',
    path: '/my-projects/drafts',
    icon: LibraryBooksIcon,
  },
  {
    title: 'Help',
    path: '/help',
    icon: HelpCenterSharpIcon,
  },
  {
    title: 'My Account',
    path: '/my-account/account-info',
    icon: PortraitSharpIcon,
  },
  {
    title: 'My Orders',
    path: '/orders',
    icon: HistorySharpIcon,
  },
];

export const unAuthPages = [
  {
    title: 'Help',
    path: '/orders',
    icon: HelpCenterSharpIcon,
  },
  {
    title: 'Login',
    path: '/sign-in',
    icon: LoginSharpIcon,
  },
];

export const adminPages = [
  {
    title: 'Materials',
    path: '/materials',
    icon: CreateIcon,
  },
  {
    title: 'Add Customer',
    path: '/add-customer',
    icon: PersonAddIcon,
  },
];

export const doorFilter = ['All', '0', '1', '2', '3', '4'];
export const drawersFilter = ['All', '0', '1', '2', '3', '4', '5', '6'];

export const cabinetSort = [
  'Popularity',
  // 'Price: High to Low',
  // 'Price: Low to High',
  'Name: A-Z',
  'Name: Z-A',
];
export const itemsPerPageFilter = ['All', '24', '48', '96'];

export const cabinetMenu = [
  {
    title: 'Home',
    path: '/',
    icon: HomeSharpIcon,
  },
  // {
  //   title: 'Featured',
  //   path: '/cabinet/featured',
  //   icon: FeaturedPlayListSharpIcon,
  // },
  {
    title: 'Base Cabinets',
    path: '/cabinet/base-cabinets',
    icon: ArrowCircleDownSharpIcon,
  },
  {
    title: 'Wall Cabinets',
    path: '/cabinet/wall-cabinets',
    icon: ArrowCircleUpSharpIcon,
  },
  {
    title: 'Tall Cabinets',
    path: '/cabinet/tall-cabinets',
    icon: ArrowCircleUpSharpIcon,
  },
  {
    title: 'Corner Cabinets',
    path: '/cabinet/corner-cabinets',
    icon: CallSplitSharpIcon,
  },
  {
    title: 'Oven Cabinets',
    path: '/cabinet/oven-cabinets',
    icon: GradeSharpIcon,
  },
  {
    title: 'Additional Items',
    path: '/cabinet/additional-items',
    icon: AddToPhotosSharpIcon,
  },
];

export const helpMenu = [
  {
    title: 'About Baru',
    onClick: () => {
      window.open(links.about, '_blank');
    },
    icon: InfoIcon,
  },
  {
    // path: '/faq',
    link: 'http://hellobaru.com/faq',
    icon: HelpIcon,
    title: 'FAQ',
  },
  {
    path: '/contact-us',
    icon: ContactsIcon,
    title: 'Contact Us',
  },
];

export const accountMenu = [
  {
    path: '/my-account/account-info',
    title: 'Account',
    icon: AccountCircleIcon,
  },
  {
    path: '/my-account/billing',
    icon: LocalShippingIcon,
    title: 'Billing',
  },
  {
    path: '/my-account/shipping',
    icon: LocalShippingIcon,
    title: 'Shipping',
  },
  // {
  //   path: '/my-account/payment',
  //   icon: PaymentIcon,
  //   title: 'Payment',
  // },
  {
    path: '/my-account/default-product-options',
    icon: CategoryIcon,
    title: 'Default product options',
  },
];
export const links = {
  about: 'https://www.hellobaru.com/about',
};

export const handRailNotchOptions = [
  {
    option: 'Yes',
    id: 1,
  },
  {
    option: 'No',
    id: 0,
  },
];

// Add Edit Materials Page constants

export const materialsOptions = [
  { id: 'Case', label: 'Case Material' },
  { id: 'Door', label: 'Door Material' },
];

export const removedCols = [
  'createdAt',
  'updatedAt',
  'id',
  'img',
  'total_count',
  'caseMessage',
  'doorMessage',
  'isDeleted',
  'isNew',
  'usage',
];

export const types = {
  dropdown: ['grain', 'outsource'],
  text: ['name', 'usage'],
};

export const expectedHeaders = [
  'Index',
  'Name',
  'MatID',
  'Grain',
  'Out Source',
  'SqFt/ Sheet',
  'Waste',
  'Cost $$/SqFt',
  'Filler $$/SqFt',
  'Panel $$/SqFT',
  'Hinge $$/Bore',
  'Add per Order',
  '3" Crown $$/Piece',
  '4" Crown $$/Piece',
  '3" Base $$/Piece',
  '4" Base $$/Piece',
  'CNC per sheet',
  'MARKUP',
  '1mm $$/LF',
  '2mm $$/LF',
  '3mm $$/LF',
  'Scribe/Shoe $$/Piece',
];

export const headersConversion = {
  index: 'Index',
  name: 'Name',
  materialId: 'MatID',
  grain: 'Grain',
  outsource: 'Out Source',
  sqFtPerSheet: 'SqFt/ Sheet',
  waste: 'Waste',
  costPerSqFt: 'Cost $$/SqFt',
  fillerCostPerSqFt: 'Filler $$/SqFt',
  panelCostPerSqFt: 'Panel $$/SqFT',
  hingeRatePerBore: 'Hinge $$/Bore',
  addPerOrder: 'Add per Order',
  threeCrownRatePerPiece: '3" Crown $$/Piece',
  fourCrownRatePerPiece: '4" Crown $$/Piece',
  threeBaseRatePerPiece: '3" Base $$/Piece',
  fourBaseRatePerPiece: '4" Base $$/Piece',
  cncPerSheet: 'CNC per sheet',
  markUp: 'MARKUP',
  oneMmCostPerLF: '1mm $$/LF',
  twoMmCostPerLF: '2mm $$/LF',
  threeMmCostPerLF: '3mm $$/LF',
  scribeShoeCostPerPiece: 'Scribe/Shoe $$/Piece',
};

export const numericFields = [
  'Index',
  'MatID',
  'SqFt/ Sheet',
  'Waste',
  'Cost $$/SqFt',
  'Filler $$/SqFt',
  'Panel $$/SqFT',
  'Hinge $$/Bore',
  'Add per Order',
  '3" Crown $$/Piece',
  '4" Crown $$/Piece',
  '3" Base $$/Piece',
  '4" Base $$/Piece',
  'CNC per sheet',
  'MARKUP',
  '1mm $$/LF',
  '2mm $$/LF',
  'Scribe/Shoe $$/Piece',
];
